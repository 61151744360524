import React, { useCallback } from 'react';
import { Link, useForm } from '@inertiajs/react';
import { AnimatePresence, motion } from 'framer-motion';
import Button from '@/components/v2/Button.jsx';
import IconButton from '@/components/v2/IconButton.jsx';
import GuestLayout from '@/components/v2/layouts/GuestLayout.jsx';
import Form from '@/components/v2/Form.jsx';
import { ReactComponent as ArrowRightIcon } from '@assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeftIcon } from '@assets/icons/arrow-left.svg';
import { ReactComponent as PolyCheckIcon } from '@assets/icons/poly-check.svg';
import { createPortal } from 'react-dom';

const RegisterInvestor = ({ turnstile_sitekey, initial_values }) => {
    const form = useForm({
        ...initial_values,
        current_step: initial_values.current_step || 0,
    });

    const steps = [
        {
            title: 'Welkom bij Capilex. Goed Investeren is Gezekerd Investeren.',
            description: 'Zet samen met Capilex het MKB in beweging.',
            buttonText: 'Vrijblijvend registreren',
            backText: 'Terug naar inloggen',
            usps: ['Vast rendement tot 7% per jaar', 'Vaste looptijd van 3, 4 of 5 jaar', 'Vastgoed als onderpand', 'Deelname vanaf 100.000 euro'],
        },
        {
            title: 'Selecteer uw belegbaar vermogen',
            description: 'Kies de investeringscategorie die het beste aansluit bij uw wensen. Wij helpen u bij de rest.',
            fields: [
                {
                    name: 'investor.investable_assets',
                    type: 'toggle-button-group',
                    label: 'Opties voor beleggingscategorieën*',
                    options: [
                        {
                            label: '€100k tot €150k',
                            value: 100000,
                        },
                        {
                            label: '€150k tot €300k',
                            value: 150000,
                        },
                        {
                            label: '€300k tot €500k',
                            value: 300000,
                        },
                        {
                            label: '€500k+',
                            value: 500000,
                        },
                    ],
                },
            ],
            buttonText: 'Ga verder',
            backText: 'Terug naar Welkom',
        },
        {
            title: 'Vul uw gegevens in',
            description: 'Om uw registratie compleet te maken, hebben we enkele persoonlijke gegevens van u nodig.',
            fields: [
                {
                    name: 'user.salutation',
                    type: 'select',
                    label: 'Aanhef*',
                    options: [
                        { value: 'mr', label: 'De heer' },
                        { value: 'mrs', label: 'Mevrouw' },
                    ],
                },
                { name: 'user.first_name', type: 'text', label: 'Voornaam*' },
                { name: 'user.last_name', type: 'text', label: 'Achternaam*' },
                { name: 'user.email', type: 'email', label: 'E-mailadres*' },
                { name: 'user.phone', type: 'phone', label: 'Telefoonnummer*' },
                {
                    name: 'cf-turnstile-response',
                    type: 'captcha',
                    label: 'Verificatie*',
                    siteKey: turnstile_sitekey,
                    key: 'turnstile-field',
                },
            ],
            buttonText: 'Vrijblijvend registreren',
            backText: 'Terug naar Belegbaar vermogen',
        },
        // Laatste stap tijdelijk uitgecommentaard
        /* {
            title: 'Uw aanmelding is voltooid!',
            description: 'Bedankt voor uw aanmelding. U ontvangt binnenkort verdere informatie over uw investeringsmogelijkheden.',
            backText: 'Terug naar inloggen',
        }, */
    ];

    const handleStepChange = (direction) => {
        const newStep = form.data.current_step + direction;
        if (newStep >= 0 && newStep < steps.length) {
            form.setData('current_step', newStep);
        }
    };

    const handleFormSubmit = useCallback(
        async (event) => {
            event.preventDefault();

            form.post(route('investor.register.store'), {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => handleStepChange(1),
            });
        },
        [form],
    );

    const renderFields = useCallback(() => {
        const step = steps[form.data.current_step];
        return step.fields.map((field) => <Form.Field key={field.key || field.name} name={field.name} {...field} />);
    }, [form.data.current_step]);

    const pageVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
    };

    return (
        <>
            {/*createPortal(
                <AnimatePresence>
                    {form.data.current_step === steps.length - 1 && (
                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }} className='pointer-events-none fixed inset-0 z-[15] flex items-center justify-center'>
                            <svg className='absolute inset-0 size-full' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid slice'>
                                <motion.circle
                                    cx='50'
                                    cy='50'
                                    r='0'
                                    fill='#BED1C9'
                                    initial={{ r: 0 }}
                                    animate={{ r: 150 }}
                                    exit={{ r: 0 }}
                                    transition={{
                                        duration: 1.5,
                                        ease: [0.22, 1, 0.36, 1],
                                    }}
                                />
                            </svg>
                        </motion.div>
                    )}
                </AnimatePresence>,
                document.body,
            )*/}

            <div className='flex grow flex-col justify-center gap-10'>
                <AnimatePresence mode='wait'>
                    <motion.div key={form.data.current_step} variants={pageVariants} initial='initial' animate='animate' exit='exit' className='flex grow flex-col justify-between gap-10 xl:grow-0' transition={{ type: 'tween', ease: 'anticipate', duration: 0.3 }}>
                        <div className={'xl:hidden'} />

                        <div className={'flex flex-col gap-10'}>
                            <div className='flex flex-col gap-5'>
                                <h1 className='text-4xl font-semibold'>{steps[form.data.current_step].title}</h1>
                                <p>{steps[form.data.current_step].description}</p>
                            </div>

                            {steps[form.data.current_step].usps?.length > 0 && (
                                <div>
                                    {steps[form.data.current_step]?.usps?.map((usp, uspIdx) => (
                                        <div key={`usp-${uspIdx}`} className={'flex items-center gap-2 border-b border-[#9b9b9b4d] py-3'}>
                                            <PolyCheckIcon className={'size-5 text-[#5D5C5C]'} />

                                            <p className={'text-sm text-[#212121b3]'}>{usp}</p>
                                        </div>
                                    ))}
                                </div>
                            )}

                            <Form form={form} onSubmit={handleFormSubmit} className={!steps[form.data.current_step]?.fields && 'hidden'}>
                                {steps[form.data.current_step]?.fields && renderFields()}
                            </Form>
                        </div>

                        <div className={'flex flex-col gap-10'}>
                            <Button size='large' className='w-full' type='submit' onClick={handleFormSubmit} loading={form.processing}>
                                <span>{steps[form.data.current_step].buttonText}</span>
                                <ArrowRightIcon className='size-6' />
                            </Button>

                            <div className='flex items-center justify-between gap-5'>
                                {form.data.current_step === 0 ? (
                                    <Link href={route('login')} className='flex items-center gap-2'>
                                        <IconButton size='small'>
                                            <ArrowLeftIcon className='size-5' />
                                        </IconButton>
                                        <p className='font-semibold'>Terug naar inloggen</p>
                                    </Link>
                                ) : (
                                    <button onClick={() => handleStepChange(-1)} className='flex items-center gap-2'>
                                        <IconButton size='small'>
                                            <ArrowLeftIcon className='size-5' />
                                        </IconButton>
                                        <p className='font-semibold'>Vorige stap</p>
                                    </button>
                                )}

                                <div className='flex justify-center gap-1'>
                                    {steps.map((_, index) => (
                                        <div key={index} className='h-1.5 w-8 rounded-lg bg-[#20202033]'>
                                            <div className='h-full rounded-lg bg-[#212121] transition-all duration-300' style={{ width: index <= form.data.current_step ? '100%' : '0%' }} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </AnimatePresence>
            </div>
        </>
    );
};

RegisterInvestor.layout = (children) => <GuestLayout>{children}</GuestLayout>;

export default RegisterInvestor;
